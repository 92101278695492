import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';

import { Button, Modal } from 'react-bootstrap';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { GrClose } from 'react-icons/gr';
import './index.scss';

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 200,
};

function getAnimationSettings(angle, originX) {
  return {
    particleCount: 3,
    angle,
    spread: 55,
    origin: { x: originX },
    colors: ['#bb0000', '#ffffff'], // TB Colors
    // colors: ['#d4af37', '#ffffff'], // NYE Colors
  };
}

function InfoModal(props) {
  const refAnimationInstance = useRef(null);
  const [intervalId, setIntervalId] = useState();

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const nextTickAnimation = useCallback(() => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(getAnimationSettings(60, 0));
      refAnimationInstance.current(getAnimationSettings(120, 1));
    }
  }, []);

  const startAnimation = useCallback(() => {
    if (!intervalId) {
      setIntervalId(setInterval(nextTickAnimation, 16));
    }
  }, [nextTickAnimation, intervalId]);

  const pauseAnimation = useCallback(() => {
    clearInterval(intervalId);
    setIntervalId(null);
  }, [intervalId]);

  const stopAnimation = useCallback(() => {
    clearInterval(intervalId);
    setIntervalId(null);
    refAnimationInstance.current &&
      refAnimationInstance.current.reset();
  }, [intervalId]);

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  useEffect(() => {
    if (props.show) startAnimation();
  }, [props.show]);

  return (
    <div className="info-modal">
      <Modal
        backdrop="static"
        className="info-modal w-100 h-100 "
        size="lg"
        show={props.show}
        onHide={props.hide}
        centered
      >
        <div className="p-2 w-100 bg-dark bg-opacity-10">
          <div className="d-flex justify-content-end text-end w-100">
            <Button
              size="sm"
              className="bg-white text-dark border-0 text-muted"
              onClick={() => {
                props.hide();
                stopAnimation();
              }}
            >
              <GrClose />
            </Button>
          </div>
          <Modal.Body className="mb-0">
            <h4 className="text-primary font-weight-normal text-center mb-4">
              {props?.data?.alertMsg}
            </h4>
            <h1 className="text-white text-center mb-4">
              {props?.data?.title}
            </h1>
            <h4 className="text-white text-center font-weight-normal mb-4">
              {props?.data?.msg}
            </h4>

            {props?.data?.link && (
              <div className="d-flex justify-content-center mt-2">
                <a
                  href={props?.data?.link}
                  target="_blank"
                  className="btn btn-secondary"
                >
                  {props?.data?.linkText ||
                    'Click Here'}
                </a>
              </div>
            )}

						{props?.data?.link2 && (
              <div className="d-flex justify-content-center mt-2">
                <a
                  href={props?.data?.link2}
                  target="_blank"
                  className="btn btn-secondary"
                >
                  {props?.data?.linkText2 ||
                    'Click Here'}
                </a>
              </div>
            )}

            {/* GRAPHIC NOTICE */}
            {/* <div className="d-flex">
              <div className="">
                <img
                  // src={}
                  alt="image"
                  // className="object-cover"
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
              <div className="">
                <img
                  // src={}
                  alt="image"
                  // className="object-cover"
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
            </div> */}
          </Modal.Body>
          {/* <BookNowButton url="https://thebunker-teefindr-live.web.app/location/thebunker/thebunkernorthgreenbush-vF8VJfondldMD1q7uRWH" /> */}
        </div>
      </Modal>
      {props.showConfetti ? (
        <ReactCanvasConfetti
          refConfetti={getInstance}
          style={canvasStyles}
        />
      ) : null}
    </div>
  );
}

export default InfoModal;
