import firebase from 'firebase/app';
import 'firebase/storage';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import locations from '../../pages/Location/config';

const CareerApplicationForm = (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [location, setLocation] = useState('');
  const [position, setPosition] = useState('');
  const [skillLevel, setSkillLevel] = useState('');
  const [message, setMessage] = useState('');
  const [resume, setResume] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailTo, setEmailTo] = useState(['careers@getinthebunker.golf']);

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const alert = useAlert();

  const formatLocation = (location) => {
    return location
      .replace(', NY', '')
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '');
  };

  const formIsInvalid = () => {
    return (
      !firstName ||
      !lastName ||
      !phone ||
      !email ||
      !message ||
      !position ||
      !location ||
      !skillLevel
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if required fields are filled out
    if (formIsInvalid()) {
      setErrorMessage('Please fill out all fields');
      return;
    }

    try {
      setSubmitting(true);

      const send_email = props.firebase.sendEmail();

      let resumeURL = null;

      if (resume !== null) {
        // Upload resume to Cloud Storage
        const storageRef = firebase.storage().ref();
        const resumeRef = storageRef.child(
          `thebunker/resumes/${resume.name}`,
        );
        await resumeRef.put(resume);

        // Get download URL for resume
        resumeURL = await resumeRef.getDownloadURL();
        console.log(resumeURL);
      }

      const payload = {
        emailTo: emailTo,
        template: 'career_template',
        phone,
        email,
        message,
        firstName,
        lastName,
        position,
        skillLevel,
        location,
        attachmentUrl: resumeURL,
        mimeType: resume?.type,
        fileName: resume?.name,
      };

      const result = await send_email(payload, { auth: true });
      alert.success('Your inquiry has been sent to The Bunker!');
      setSubmitted(true);
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);

      setFirstName('');
      setLastName('');
      setPhone('');
      setEmail('');
      setMessage('');
      setLocation('');
      setPosition('');
      setSkillLevel('');
      setResume(null);
      setErrorMessage('');
    }
  };

  const handleResumeChange = (event) => {
    const file = event.target.files[0];
    if (
      file.type === 'application/pdf' ||
      file.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      setResume(file);
      setErrorMessage('');
    } else {
      setResume(null);
      setErrorMessage('Only PDF and docx files are allowed');
    }
  };

  return (
    <>
      {submitted ? (
        <p
          className="text-primary font-weight-bold mb-0"
          style={{ fontSize: 36 }}
        >
          Your inquiry has been sent to The Bunker!
        </p>
      ) : (
        <Form className="p-3" onSubmit={handleSubmit}>
          <Form.Group controlId="firstName" className="mb-3">
            <Form.Label>First Name:</Form.Label>
            <Form.Control
              type="text"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="lastName" className="mb-3">
            <Form.Label>Last Name:</Form.Label>
            <Form.Control
              type="text"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formBasicPhone" className="mb-3">
            <Form.Label>Phone Number:</Form.Label>
            <Form.Control
              as={PhoneInput}
              country="US"
              value={phone}
              onChange={setPhone}
              required
            />
          </Form.Group>

          <Form.Group controlId="email" className="mb-3">
            <Form.Label>Email:</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
            />
          </Form.Group>

          <Form.Group
            controlId="position"
            className="mb-3 d-flex flex-column"
          >
            <Form.Label>Position:</Form.Label>
            <Form.Control
              as="select"
              className="p-2 rounded"
              style={{ borderColor: 'lightgray' }}
              value={position}
              onChange={(event) =>
                event.target.value !== 'default' &&
                setPosition(event.target.value)
              }
            >
              <option value="default" selected>
                Select a Position
              </option>
              <option value="Bartender">Bartender</option>
              <option value="Server">Server</option>
              <option value="Manager">Manager</option>
              <option value="Chef/Kitchen Staff">
                Chef/Kitchen Staff
              </option>
              <option value="Golf Instructor">Golf Instructor</option>
              <option value="Other">Other</option>
            </Form.Control>
          </Form.Group>

          <Form.Group
            controlId="location"
            className="mb-3 d-flex flex-column"
          >
            <Form.Label>Location:</Form.Label>
            <Form.Control
              as="select"
              className="p-2 rounded"
              style={{ borderColor: 'lightgray' }}
              required
              value={location}
              onChange={(event) => {
                if (event.target.value !== 'default') {
                  setLocation(event.target.value);
                  const id = formatLocation(event.target.value);
                  const targetLoc = locations.find(
                    (l) => l.id === id,
                  );
                  if (!targetLoc) return;

                  setEmailTo(
                    targetLoc.careerEmails || [
                      'careers@getinthebunker.golf',
                    ],
                  );
                }
              }}
            >
              <option value="default" selected>
                Select a Location
              </option>
              <option value="Guilderland, NY">Guilderland, NY</option>
              <option value="Clifton Park, NY">
                Clifton Park, NY
              </option>
              <option value="New Hartford, NY">
                New Hartford, NY
              </option>
              <option value="North Greenbush, NY">
                North Greenbush, NY
              </option>
              <option value="Mohawk Harbor, NY">
                Mohawk Harbor, NY
              </option>
              <option value="Saratoga, NY">Saratoga, NY</option>
              <option value="Latham, NY">Latham, NY</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="skillLevel" className="mb-3">
            <Form.Label>Skill Level:</Form.Label>
            <Form.Control
              type="text"
              value={skillLevel}
              onChange={(event) => setSkillLevel(event.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="message" className="mb-3">
            <Form.Label>Details / Past Experience:</Form.Label>
            <Form.Control
              as="textarea"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="resume" className="mb-3">
            <Form.Label>Resume (PDF or .docx files only):</Form.Label>
            <Form.Control
              style={{ border: 'none' }}
              type="file"
              accept=".pdf,.docx"
              onChange={handleResumeChange}
              // required
            />
          </Form.Group>

          {errorMessage && (
            <div className="text-danger">{errorMessage}</div>
          )}

          <Button
            variant="success"
            type="submit"
            disabled={submitting === true}
            className="mt-3"
          >
            Submit
          </Button>
        </Form>
      )}
    </>
  );
};

export default CareerApplicationForm;
