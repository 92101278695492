import React, { useState } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import './dropdown.scss'; // Use the updated SCSS file

const DropDownComponent = (navitem) => {
  const [show, setShow] = useState(false);
  const history = useHistory();

  // Toggle dropdown visibility
  const toggle = () => {
    setShow(!show);
  };

  return (
    <div className="w-100 dropdown-container">
      <div
        className="py-2 text-left text-white d-flex justify-content-center align-items-center"
        onClick={toggle}
      >
        <span className={`dropdown-title`}>{navitem.title}</span>
        <MdKeyboardArrowDown
          className={`text-primary dropdown-arrow ${
            show ? 'rotate-180' : ''
          }`}
          onClick={toggle}
        />
      </div>
      <div
        className={`dropdown-content overflow-hidden transition-all ${
          show ? 'show-content' : 'hide-content'
        }`}
      >
        {navitem.options.map((dropdownitem) => {
          return dropdownitem.href ? (
            <a
              href={dropdownitem.href}
              className="dropdown-link hover-text-primary"
              key={dropdownitem.id + '-desktop'}
            >
              {dropdownitem.title}
            </a>
          ) : (
            <Link
              to={dropdownitem.to}
              className="dropdown-link hover-text-primary"
              key={dropdownitem.id + '-desktop'}
            >
              {dropdownitem.title}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default DropDownComponent;
