import React from 'react';
import { Button } from 'react-bootstrap';

function BookLessonsButton(props) {
  return (
    <div className={`${props?.style} d-flex m-1 mb-2`}>
      <Button
        variant="primary"
        className="w-100"
        size="sm"
        onClick={() => {
          window.location.href = 'https://www.bunkerlessons.com';
        }}
      >
        BOOK LESSON
      </Button>
    </div>
  );
}

export default BookLessonsButton;
