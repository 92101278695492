// src/pages/TourPage/index.jsx

import React, { useState } from 'react';
import { withFirebase } from '../../components/Firebase';
import '../../utils/shared.scss';
import { Footer } from '../Landing';
import './index.scss';
// import LessonInterestForm from '../../components/LessonInterestForm';
import { Button, Modal, Spinner } from 'react-bootstrap';

const HEADER = 'TOUR';
const TITLE = 'THE BUNKER TOUR';

const TOUR_SIGNUP_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSfGCRFe8MsAE9nToAm6A5l0Qr4OQ1xGYFtkp7CyWEewWsT8LQ/viewform';

const TourPage = (props) => {
  // State for loading and submission confirmation
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmission = (status) => {
    setIsLoading(false);
    if (status === 'success') {
      setShowConfirmation(true);
    }
    // No action needed for 'error' status here; error message is handled in the form component
  };

  const SignUpNowButton = () => {
    return (
      <Button className="sign-up-btn">
        <a target="_blank" href={TOUR_SIGNUP_LINK}>
          REGISTER NOW
        </a>
      </Button>
    );
  };

  const Title = (
    <div className="title-container">
      <div className="overlay">
        <h1 className="title text-white m-0">{HEADER}</h1>
      </div>
      <img
        src="https://storage.googleapis.com/thebunker-assets/thebunker/Bunker_Membership_Web_Page_Header.jpg"
        alt="Lessons Background"
        width="100%"
        height="100%"
      />
    </div>
  );

  const TourDates = (
    <div className="payment-options">
      <div className="section-title pb-3">Bunker Tour Details</div>
      <p>
        Made up of month-long “major” tournaments that will follow the
        PGA Tour schedule when they are playing a course we offer.
        Bunker Tour participants can play ANY time during the month to
        participate.
      </p>
      <p><span className="font-weight-bold">December</span>- Hero World Challenge @ Albany GC</p>
      <p><span className="font-weight-bold">January</span>- AT&T Pro am @ Pebble Beach</p>
      <p><span className="font-weight-bold">February</span>- Cognizant Classic @ PGA National</p>
      <p><span className="font-weight-bold">March</span>- Valspar Champ @ Innisbrook</p>
      <SignUpNowButton />
    </div>
  );

	const TourChampionship = (
    <div className="payment-options">
      <div className="section-title pb-3">Tour Championship</div>
			<p>The Tour Championship will take place April 13th-April 30th. You may play your round anytime between those dates at any location. You may participate in the Tour Championship if you played in at least one of the four tour events and paid the tour fee. The entry fee for the Tour Championship is $150 per team. Entry fee covers simulator time and additional prize money. The Tour Championship will be played on Quail Hollow (Site of the 2025 PGA Championship).</p>
		</div>
	);

  const Details = (
    <div className="details">
      <div className="section-title">DETAILS</div>

      <ul>
        <li key={1} className="py-2">
          To join the tour there is a{' '}
          <span className="font-weight-bold">$100 fee</span> that will
          go directly into the prize pot to pay out winners from each
          tournament. There will be one winner each month across all
          locations
        </li>
        <li key={2} className="py-2">
          The tournament fee will be paid online via website or QR
          code in store
        </li>
        <li key={3} className="py-2">
          People will then pay the regular sim rate at the location
          when they play their tournament round{' '}
        </li>
        <li key={4} className="py-2">
          Format two-person best ball gross 18 holes{' '}
        </li>
        <li key={5} className="py-2">
          A tour championship will be held at one location in April
          for the top teams that qualify
        </li>
				<li key={6} className="py-2">
					You need to create a Trackman account to participate. To save time you should download the Trackman app and create your account before your first round if you don't already have one. You could also create one at the facility before you play if you don't want to download the app. The app will also allow you to follow the leaderboard for each tournament
				</li>
				<li key={7} className="py-2">
					Mulligans will be on but only to be used if there is a glitch. For example you make a practice swing and it sees it as a shot. If you are going to use a mulligan please make a staff member aware of the situation
				</li>
				<li key={8} className="py-2">
					We recommend taking a picture of your scorecard at the end of your round just in case your score doesn't get saved properly and needs to be entered manually
				</li>
      </ul>
    </div>
  );


  const Main = () => {
    return (
      <div className="main px-3 flex-fill h-100">
        <div className="pt-4 d-flex flex-column">
          <h1 className="text-dark mb-4">{TITLE}</h1>

          {TourDates}
          <hr />
					{TourChampionship}
          <hr />
          {Details}
          <hr />
        </div>
      </div>
    );
  };

  return (
    <div className="membership-container">
      {Title}
      {isLoading && (
        <div className="loading-overlay">
          <Spinner animation="border" variant="success" />
          <p>Submitting your inquiry...</p>
        </div>
      )}
      <Main />
      <Footer />

      {/* Submission Confirmation Modal */}
      <Modal
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Thank You!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Your inquiry has been sent! We'll get back to you shortly.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => setShowConfirmation(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default withFirebase(TourPage);
